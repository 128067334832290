import React from 'react'
import { Card } from 'theme-ui'
import ContactForm from '@components/MiniForm'
import useForm from '@helpers/useForm'

const MiniForm = () => {
  const { handleSubmit, submitting, success } = useForm()

  return (
    <Card variant='horizontal'>
      <ContactForm {...{ handleSubmit, submitting, success }} />
    </Card>
  )
}

export default MiniForm
