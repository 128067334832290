import React from 'react'
import { Heading, Box } from 'theme-ui'
import MiniForm from '@widgets/MiniForm'

/**
 * Shadow me to add your own content
 */

const styles = {
  wrapper: {
    ml: 3
  },
  heading: {
    color: `alpha`
  },
  running: {
    color: `omegaDark`,
    fontWeight: `body`
  }
}

export default () => (
  <Box id="miniform" sx={styles.wrapper}>
    <Heading variant='h1' sx={styles.heading}>
      Sizi geri arayalım!
    </Heading>
    <Heading variant='h2' sx={styles.running}>
      Telefon numaranızı bırakın, danışmanlarımız ilk fırsatta size dönüş sağlasınlar. 
    </Heading>
    <MiniForm />
  </Box>
)
